import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useSearchParams } from 'react-router-dom';

const supportedLanguages = [
  { value: 'en', label: 'English' },
  { value: 'es-US', label: 'Español (US)' },
  { value: 'es-MX', label: 'Español (MX)' },
  { value: 'vi-VN', label: 'Tiếng Việt' },
  { value: 'zh-Hans', label: '简体中文' },
  { value: 'zh-Hans-HK', label: '繁體中文 (HK)' },
];

const getSupportedLanguage = ( lang: string ): string => {
  // return exact match if supported
  if( supportedLanguages.find( l => l.value === lang ) ) return lang;

  // split language into parts
  // N.B. the code may have a third piece (e.g. zh-Hans-HK) but would be matched above exactly.
  const [ baseLang = '', country = '' ] = lang.split( '-' );

  // find supported languages with the same base language, defaulting to English if none found
  const matches = supportedLanguages.filter( l => l.value.startsWith( baseLang ) );
  if( !matches.length ) return supportedLanguages[ 0 ].value;

  // find a matching country if supported, otherwise return a match with the same base language
  return matches.find( l => {
    const [ , matchedCountry = '' ] = l.value.split( '-' );
    return matchedCountry === country;
  } )?.value || matches[ 0 ].value;
};

export interface LanguageSwitcherProps {
  lang?: string; // supplied from recipient data like feedback
}

export const LanguageSwitcher: FC<LanguageSwitcherProps> = props => {
  const { i18n } = useTranslation( 'translation', { useSuspense: false } );
  const [ , setSearchParams ] = useSearchParams();

  useEffect( () => {
    // ensure the selected language is supported
    const overrideProfile = localStorage.getItem( 'override-profile-lang' );
    const current = overrideProfile ? i18n.language : i18n.language == 'en' && props.lang ? props.lang : i18n.language;
    const lang = getSupportedLanguage( current );
    // language query interferes with the dropdown selector, so remove it after choosing the language
    setSearchParams( params => {
      params.delete( 'lng' );
      return params;
    } );
    if( lang != i18n.language ) i18n.changeLanguage( lang );
  }, [ i18n.language, props.lang ] );

  const handleLanguageChange = async ( e: SelectChangeEvent ) => {
    const newLang = e.target.value;
    localStorage.setItem( 'override-profile-lang', '1' );
    await i18n.changeLanguage( newLang );
    // @ts-ignore: 2339
    window.i18nextify.forceRerender();
    window.location.reload();
  };

  return (
    <Select
      value={i18n.language}
      onChange={handleLanguageChange}
      size='small'
      className='translated'
      sx={{
        verticalAlign: 'sub',
        '& .MuiSelect-select': {
          paddingBottom: '2px',
          paddingTop: '2px',
        },
      }}
    >
      {supportedLanguages.map( lang => (
        <MenuItem key={lang.value} value={lang.value} className='translated'>
          {lang.label}
        </MenuItem>
      ) )}
    </Select>
  );
};
